import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Slider`}</inlineCode>{` merupakan Sebuah unsur UI, biasanya trek horizontal digunakan untuk mengatur nilai dengan menggeser indikator.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`One handling`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Default" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "468px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA30lEQVQ4y+2TvQrCMBRG+/6IIDj4Dg4uLro7ujg5iVi0rdRKW5vk9sfPtNqYqIUKunU4JISbL+cmxEp4il9idYH/C7wwUqOOvva1Yb2JUw6RFmAiawxSgZ9OVsi16CLgHgPsXR+nc/RSl1Yk/Dm3dBtW2og7TBZl+RWbrY3BcIRef4DxZAqSppVAJUEqrJ4bhn4o4AQcrsQJGLyzgO2FmC1WGM+X2Nl7aZ0h1uzeDJsC61A/JOxOhPWBIy+AKDHDWrf8bL0cCUQp4kQ8Lp8MGlt+exSN2KhpYdh9vS6wPTeqOg7dh2hd6wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e12ed65d4dafff6704035f9d7d3cdead/d5a55/default.png",
              "srcSet": ["/static/e12ed65d4dafff6704035f9d7d3cdead/7fc1e/default.png 288w", "/static/e12ed65d4dafff6704035f9d7d3cdead/d5a55/default.png 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Disabled" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "468px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAYklEQVQ4y2P4+v3Xf2pihlEDR4KBX779/A/CIA6MjQvjUoMsDnchTAIX/vbjN041yOIoLvz89cf/T0D8GQ1//Pzt//uPX4hzIT4DYewPn77+f/fh8yDwMlUjZTSnjBpIPAYAPYwU9hkxtHMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/3e78063c15d1b9e2c0b30b1c5c6fad5c/d5a55/disabled.png",
              "srcSet": ["/static/3e78063c15d1b9e2c0b30b1c5c6fad5c/7fc1e/disabled.png 288w", "/static/3e78063c15d1b9e2c0b30b1c5c6fad5c/d5a55/disabled.png 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Range`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Default" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "468px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAlElEQVQ4y+2Tyw7CIBBF+f9fs/0C69oaH2DaDgVMrjOlBBNbE6NxI4sThgn3ZAhBDdbjm6gi/AdhTx7CYN28yt6hIzetj6RePJNJWUHJJstigDhwCwE0etgZqQP3pI7STMoKixOeryOqxuCgLfaXyJF79c6g5Vrk3dqES8IThzdb/SSsGs01vRb+5MofPkr5KUX4LnfEkBOO+FzM9wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e93114f57a906141a78fde8667b6a105/d5a55/range.png",
              "srcSet": ["/static/e93114f57a906141a78fde8667b6a105/7fc1e/range.png 288w", "/static/e93114f57a906141a78fde8667b6a105/d5a55/range.png 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Disabled" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "468px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAaElEQVQ4y2P4+v3Xf2pihlEDR4KBX779/A/CIA6MTSpG1gt3IUwChr/9+I1hOzYxdL1YXfj564//7z58BtMg/AlKg8Q+ffmO34W4DHz7/hPCwC8/4GIgA78OuJcJRwIRLhzNKaMGEo8B50kU23UXxKYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/b07b8c9517ab7601dd4f73d91a90874d/d5a55/range-disabled.png",
              "srcSet": ["/static/b07b8c9517ab7601dd4f73d91a90874d/7fc1e/range-disabled.png 288w", "/static/b07b8c9517ab7601dd4f73d91a90874d/d5a55/range-disabled.png 468w"],
              "sizes": "(max-width: 468px) 100vw, 468px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      